import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_COUPON_LIST = "GET_COUPON_LIST";
export const ADD_COUPON_NEW_REQUEST = "ADD_COUPON_NEW_REQUEST";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const EDIT_COUPON = "EDIT_COUPON";
export const UPDATE_COUPON = "UPDATE_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const FETCH_COUPON_REQUEST = "FETCH_COUPON_REQUEST";
export const COUPON_FINISH_WORK = "COUPON_FINISH_WORK";

export const getCouponList = () => dispatch => {
    axios.get(`${API_URLL}coupon/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_COUPON_LIST,
            payload:res.data
        });
    });
}

export const addCoupon = (payload) => dispatch => {
    dispatch({type:ADD_COUPON_NEW_REQUEST})
    axios.post(`${API_URLL}coupon/create`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_COUPON_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:COUPON_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const updateCoupon = (payload, id) => dispatch => {
 dispatch({type:ADD_COUPON_NEW_REQUEST})
    axios.put(`${API_URLL}coupon/update/${id}`, 
    payload,{
        headers: {
            'x-access-token': `${token}`
        }
    })
    .then(res=>{
        dispatch({
            type:ADD_COUPON_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:COUPON_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const deleteCoupon = (payload, id) => dispatch => {
dispatch({type:ADD_COUPON_NEW_REQUEST})
    axios.delete(`${API_URLL}coupon/${id}`,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_COUPON_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:COUPON_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const successOff = () => dispatch => {
    dispatch({
        type:COUPON_FINISH_WORK
    })
}