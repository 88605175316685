const {GET_TRYATHOME_LIST, TRYATHOME_FINISH_WORK} = require('./../actions/TryathomeAction');

const initialState = {
    getTryathomeList:[],
    loading:false,
    success:false
}

const TestimonialReducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_TRYATHOME_LIST:
            return{
                ...state,
                getTryathomeList:action.payload
            }
            break;
       
        case TRYATHOME_FINISH_WORK:
            return{
                ...state,
                success:false
            }
            break;
    
        default:
        return {
            ...state,
            success:false
        }
    }
}

export default TestimonialReducer