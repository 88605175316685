import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_METAL_LIST = "GET_METAL_LIST";
export const ADD_METAL_NEW_REQUEST = "ADD_METAL_NEW_REQUEST";
export const ADD_METAL_SUCCESS = "ADD_METAL_SUCCESS";
export const EDIT_METAL = "EDIT_METAL";
export const UPDATE_METAL = "UPDATE_METAL";
export const DELETE_METAL = "DELETE_METAL";
export const FETCH_METAL_REQUEST = "FETCH_METAL_REQUEST";
export const METAL_FINISH_WORK = "METAL_FINISH_WORK";

export const getMetalList = () => dispatch => {
    axios.get(`${API_URLL}metal/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_METAL_LIST,
            payload:res.data
        })
    })
}

export const addMetal = (payload) => dispatch => {
    dispatch({type:ADD_METAL_NEW_REQUEST})
    axios.post(`${API_URLL}metal/create`,payload,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:ADD_METAL_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:METAL_FINISH_WORK})
        }, 2000);
    })
}

export const updateMetal = (payload, id) => dispatch => {
    dispatch({type:ADD_METAL_NEW_REQUEST})
    axios.put(`${API_URLL}metal/update/${id}`,payload,{
       headers: {
        'x-access-token': `${token}`
    }  
    })
    .then(res=>{
        dispatch({
            type:ADD_METAL_SUCCESS,
            payload:res.data
        })
        setTimeout(() => {
            dispatch({type:METAL_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        
    })
}

export const deleteMetal = (payload, id) => dispatch => {
    axios.delete(`${API_URLL}metal/delete/${id}`,{
         headers: {
        'x-access-token': `${token}`
    }
    })
    .then(res=>{
        dispatch({
            type:ADD_METAL_SUCCESS,
            payload:res.data
        })
        setTimeout(() => {
            dispatch({type:METAL_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        
    })
}

export const successOff = () => dispatch => {
    dispatch({
        type:METAL_FINISH_WORK
    })
}