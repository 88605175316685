import { MatxLoadable } from "matx";

const TagsList = MatxLoadable({
  loader: () => import("./TagsList")
});


const tagsRoutes = [
  {
    path: "/tags/list",
    component: TagsList
  },

];

export default tagsRoutes;