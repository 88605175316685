import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_OFFER_LIST = "GET_OFFER_LIST";
export const ADD_OFFER_NEW_REQUEST = "ADD_OFFER_NEW_REQUEST";
export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS";
export const EDIT_OFFER = "EDIT_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const DELETE_OFFER = "DELETE_OFFER";
export const FETCH_OFFER_REQUEST = "FETCH_OFFER_REQUEST";
export const OFFER_FINISH_WORK = "OFFER_FINISH_WORK";

export const getOfferList = () => dispatch => {
    axios.get(`${API_URLL}offer/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_OFFER_LIST,
            payload:res.data
        });
    });
}

export const addOffer = (payload) => dispatch => {
    dispatch({type:ADD_OFFER_NEW_REQUEST})
    axios.post(`${API_URLL}offer/create`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_OFFER_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:OFFER_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        
        console.log(error)
    })
}

export const updateOffer = (payload, id) => dispatch => {
 dispatch({type:ADD_OFFER_NEW_REQUEST})
    axios.put(`${API_URLL}offer/update/${id}`, 
    payload,{
        headers: {
            'x-access-token': `${token}`
        }
    })
    .then(res=>{
        dispatch({
            type:ADD_OFFER_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:OFFER_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const deleteOffer = (payload, id) => dispatch => {
dispatch({type:ADD_OFFER_NEW_REQUEST})
    axios.delete(`${API_URLL}offer/delete/${id}`,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_OFFER_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:OFFER_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const successOff = () => dispatch => {
    dispatch({
        type:OFFER_FINISH_WORK
    })
}