import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_TAGS_LIST = "GET_TAGS_LIST";
export const ADD_TAGS_NEW_REQUEST = "ADD_TAGS_NEW_REQUEST";
export const ADD_TAGS_SUCCESS = "ADD_TAGS_SUCCESS";
export const EDIT_TAGS = "EDIT_TAGS";
export const UPDATE_TAGS = "UPDATE_TAGS";
export const DELETE_TAGS = "DELETE_TAGS";
export const FETCH_TAGS_REQUEST = "FETCH_TAGS_REQUEST";
export const TAGS_FINISH_WORK = "TAGS_FINISH_WORK";

export const getTagsList = () => dispatch => {
     axios.get(`${API_URLL}tags/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_TAGS_LIST,
            payload:res.data
        });
    });
}

export const addTags = (payload) => dispatch => {
     dispatch({type:ADD_TAGS_NEW_REQUEST})
    axios.post(`${API_URLL}tags/create`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_TAGS_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:TAGS_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const updateTags = (payload, id) => dispatch => {
     dispatch({type:ADD_TAGS_NEW_REQUEST})
    axios.put(`${API_URLL}tags/update/${id}`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_TAGS_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:TAGS_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const successOff = () => dispatch => {
    dispatch({
        type:TAGS_FINISH_WORK
    })
}

export const deleteTags = (payload, id) => dispatch => {
     dispatch({type:ADD_TAGS_NEW_REQUEST})
    axios.delete(`${API_URLL}tags/update/${id}`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:ADD_TAGS_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:TAGS_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}