const {GET_TESTIMONIAL_LIST, ADD_TESTIMONIAL_NEW_REQUEST, ADD_TESTIMONIAL_SUCCESS, TESTIMONIAL_FINISH_WORK} = require('./../actions/TestimonialAction');

const initialState = {
    getTestimonialList:[],
    loading:false,
    success:false
}

const TestimonialReducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_TESTIMONIAL_LIST:
            return{
                ...state,
                getTestimonialList:action.payload
            }
            break;
        case ADD_TESTIMONIAL_NEW_REQUEST:
            return{
                ...state,
                loading:true,
            }
            break;
        case ADD_TESTIMONIAL_SUCCESS:
            return{
                ...state,
                loading:false,
                success:true
            }
            break;
        case TESTIMONIAL_FINISH_WORK:
            return{
                ...state,
                success:false
            }
            break;
    
        default:
        return {
            ...state,
            success:false
        }
    }
}

export default TestimonialReducer