import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_GIFT_LIST = "GET_GIFT_LIST";
export const ADD_GIFT_NEW_REQUEST = "ADD_GIFT_NEW_REQUEST";
export const ADD_GIFT_SUCCESS = "ADD_GIFT_SUCCESS";
export const EDIT_GIFT = "EDIT_GIFT";
export const UPDATE_GIFT = "UPDATE_GIFT";
export const DELETE_GIFT = "DELETE_GIFT";
export const FETCH_GIFT_REQUEST = "FETCH_GIFT_REQUEST";
export const GIFT_FINISH_WORK = "GIFT_FINISH_WORK";

export const getGiftList = () => dispatch => {
    axios.get(`${API_URLL}gift/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_GIFT_LIST,
            payload:res.data
        });
    });
}

export const addGift = (payload) => dispatch => {
    dispatch({type:ADD_GIFT_NEW_REQUEST})
    axios.post(`${API_URLL}gift/create`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_GIFT_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:GIFT_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const updateGift = (payload, id) => dispatch => {
 dispatch({type:ADD_GIFT_NEW_REQUEST})
    axios.put(`${API_URLL}gift/update/${id}`, 
    payload,{
        headers: {
            'x-access-token': `${token}`
        }
    })
    .then(res=>{
        dispatch({
            type:ADD_GIFT_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:GIFT_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const deleteGift = (payload, id) => dispatch => {
dispatch({type:ADD_GIFT_NEW_REQUEST})
    axios.delete(`${API_URLL}gift/${id}`,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_GIFT_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:GIFT_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const successOff = () => dispatch => {
    dispatch({
        type:GIFT_FINISH_WORK
    })
}