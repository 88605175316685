import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'

export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const ADD_EMPLOYEE_NEW_REQUEST = "ADD_EMPLOYEE_NEW_REQUEST";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const FETCH_EMPLOYEE_REQUEST = "FETCH_EMPLOYEE_REQUEST";
export const EMPLOYEE_FINISH_WORK = "EMPLOYEE_FINISH_WORK";

export const getEmployeeList = ()  => dispatch => {
    axios.get(`${API_URLL}employee/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_EMPLOYEE_LIST,
            payload:res.data
        });
    })
    .catch(error=>{
        console.log('error')
        console.log(error)
    })
}


export const addEmployee = (payload)  => dispatch =>{
    console.log(payload)
    dispatch({type:ADD_EMPLOYEE_NEW_REQUEST})
    axios.post(`${API_URLL}employee/create`,
    payload,
    {
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_EMPLOYEE,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:EMPLOYEE_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const editEmployee = (payload) => dispatch => {
    dispatch({type:ADD_EMPLOYEE_NEW_REQUEST})
    axios.post(`${API_URLL}employee/edit/${payload}`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({type:EDIT_EMPLOYEE, payload:res.data})
    }) 
}

export const updateEmployee = (payload, id) => dispatch => {
    dispatch({type:ADD_EMPLOYEE_NEW_REQUEST})
     axios.put(`${API_URLL}employee/update/${id}`, 
     payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        
        dispatch({
            type:ADD_EMPLOYEE,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:EMPLOYEE_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log(`error ${error}`)
    })   
}

export const deleteEmployee = (payload, id) => dispatch => {
    dispatch({type:ADD_EMPLOYEE_NEW_REQUEST})
    axios.delete(`${API_URLL}employee/delete/${id}`,{
            headers: {
        'x-access-token': `${token}`
    }
    })
    .then(res=>{
        dispatch({
            type:ADD_EMPLOYEE,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:EMPLOYEE_FINISH_WORK})
        }, 2000);
    });
}

export const successOff = () => dispatch => {
    dispatch({
        type:EMPLOYEE_FINISH_WORK
    })
}