import { MatxLoadable } from "matx";

const TryAtHomeList = MatxLoadable({
  loader: () => import("./TryAtHomeList")
});

const orderRoutes = [
  {
    path: "/tryathome/list",
    component: TryAtHomeList
  }, 
];

export default orderRoutes;