const {GET_JEWELLERY_TYPE_LIST, ADD_JEWELLERY_TYPE_NEW_REQUEST, ADD_JEWELLERY_TYPE_SUCCESS, JEWELLERY_TYPE_FINISH_WORK} = require('./../actions/JewelleryTypeAction');

const initialState = {
    getJewelleryTypeList:[],
    loading:false,
    success:false
}

const JewelleryTypeReducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_JEWELLERY_TYPE_LIST:
            return{
                ...state,
                getJewelleryTypeList:action.payload
            }
            break;
        case ADD_JEWELLERY_TYPE_NEW_REQUEST:
            return{
                ...state,
                loading:true,
            }
            break;
        case ADD_JEWELLERY_TYPE_SUCCESS:
            return{
                ...state,
                loading:false,
                success:true
            }
            break;
        case JEWELLERY_TYPE_FINISH_WORK:
            return{
                ...state,
                success:false
            }
            break;
    
        default:
        return {
            ...state,
            success:false
        }
    }
}

export default JewelleryTypeReducer