import { ADD_CATEGORY, ADD_CATEGORY_NEW_REQUEST, CATEGORY_FINISH_WORK, EDIT_CATEGORY, GET_CATEGORY_LIST } from './../actions/CategoryAction';

const initialState = {
    categoryList: [],
    addCategory: [],
    loading: false,
    success: false
};

const CategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORY_LIST:
            return {
                ...state,
                categoryList: action.payload
            };
        case ADD_CATEGORY_NEW_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case ADD_CATEGORY:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case EDIT_CATEGORY:
            return {
                ...state,
                loading: false,
            };
        case CATEGORY_FINISH_WORK:
            return {
                ...state,
                success: false
            };
        default:
            return state;
    }
};

export default CategoryReducer;