const {GET_GIFT_LIST, ADD_GIFT_NEW_REQUEST, ADD_GIFT_SUCCESS, GIFT_FINISH_WORK} = require('./../actions/GiftAction');

const initialState = {
    giftList:[],
    loading:false,
    success:false
}

const GiftReducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_GIFT_LIST:
            return{
                ...state,
                giftList:action.payload
            }
            break;
        case ADD_GIFT_NEW_REQUEST:
            return{
                ...state,
                loading:true,
            }
            break;
        case ADD_GIFT_SUCCESS:
            return{
                ...state,
                loading:false,
                success:true
            }
            break;
        case GIFT_FINISH_WORK:
            return{
                ...state,
                success:false
            }
            break;
    
        default:
        return {
            ...state,
            success:false
        }
    }
}

export default GiftReducer