import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import CustomerReducer from "./CustomerReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import CategoryReducer from "./CategoryReducer";
import PriceReducer from "./PriceReducer";
import MetalReducer from "./MetalReducer";
import StonesReducer from "./StonesReducer";
import TagsReducer from "./TagsReducer";
import TestimonialReducer from "./TestimonialReducer";
import NewsReducer from "./NewsReducer";
import CollectionReducer from "./CollectionReducer";
import OfferReducer from "./OfferReducer";
import JewelleryTypeReducer from "./JewelleryTypeReducer";
import BannerReducer from "./BannerReducer";
import OccasionReducer from "./OccasionReducer";
import ProductReducer from "./ProductReducer";
import PurityReducer from "./PurityReducer";
import EmployeeReducer from "./EmployeeReducer";
import OrderReducer from "./OrderReducer";
import TryathomeReducer from "./TryathomeReducer";
import ReportReducer from "./ReportReducer";
import CouponReducer from "./CouponReducer";
import GiftReducer from "./GiftReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  customers:CustomerReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notification: NotificationReducer,
  ecommerce: EcommerceReducer,
  category:CategoryReducer,
  price:PriceReducer,
  offer:OfferReducer,
  collection:CollectionReducer,
  jewellerytype:JewelleryTypeReducer,
  purity:PurityReducer,
  occasion:OccasionReducer,
  product:ProductReducer,
  stones:StonesReducer,
  tags:TagsReducer,
  testimonial:TestimonialReducer,
  news:NewsReducer,
  metal:MetalReducer,
  employees:EmployeeReducer,
  orders:OrderReducer,
  tryathomes:TryathomeReducer,
  banners:BannerReducer,
  reports:ReportReducer,
  coupon:CouponReducer,
  gift:GiftReducer,
});

export default RootReducer;
