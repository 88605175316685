import { MatxLoadable } from "matx";

const ReportList = MatxLoadable({
  loader: () => import("./ReportList")
});


const reportRoutes = [
  {
    path: "/report/list",
    component: ReportList
  },

];

export default reportRoutes;