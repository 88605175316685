import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_OCCASION_LIST = "GET_OCCASION_LIST";
export const ADD_OCCASION_NEW_REQUEST = "ADD_OCCASION_NEW_REQUEST";
export const ADD_OCCASION_SUCCESS = "ADD_OCCASION_SUCCESS";
export const EDIT_OCCASION = "EDIT_OCCASION";
export const UPDATE_OCCASION = "UPDATE_OCCASION";
export const DELETE_OCCASION = "DELETE_OCCASION";
export const FETCH_OCCASION_REQUEST = "FETCH_OCCASION_REQUEST";
export const OCCASION_FINISH_WORK = "OCCASION_FINISH_WORK";

export const getOccasionList = () => dispatch => {
    axios.get(`${API_URLL}occasion/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_OCCASION_LIST,
            payload:res.data
        });
    });
}

export const addOccasion = (payload) => dispatch => {
    dispatch({type:ADD_OCCASION_NEW_REQUEST})
    axios.post(`${API_URLL}occasion/create`,
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:ADD_OCCASION_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:OCCASION_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const updateOccasion = (payload, id) => dispatch => {
    dispatch({type:ADD_OCCASION_NEW_REQUEST})
    axios.put(`${API_URLL}occasion/update/${id}`,payload,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:ADD_OCCASION_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:OCCASION_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const deleteOccasion = (payload, id) => dispatch => {
dispatch({type:ADD_OCCASION_NEW_REQUEST})
    axios.delete(`${API_URLL}occasion/delete/${id}`,payload,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:ADD_OCCASION_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:OCCASION_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const successOff = () => dispatch => {
    dispatch({
        type:OCCASION_FINISH_WORK
    })
}