import { MatxLoadable } from "matx";

const MetalList = MatxLoadable({
  loader: () => import("./MetalList")
});


const metalRoutes = [
  {
    path: "/metal/list",
    component: MetalList
  },

];

export default metalRoutes;