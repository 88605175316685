import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";
import './../custom_css.css';
const Dashboard = MatxLoadable({
  loader: () => import("./Dashboard")
})

const dashboardRoutes = [
  {
    path: "/",
    component: Dashboard,
    auth: authRoles.admin
  }
];

export default dashboardRoutes;