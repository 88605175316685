import history from "history.js";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "../fake-db";
import "../styles/_app.scss";
import MatxTheme from "./MatxLayout/MatxTheme/MatxTheme";
import AppContext from "./appContext";

import MatxLayout from "./MatxLayout/MatxLayout";
import routes from "./RootRoutes";
import Auth from "./auth/Auth";
import AuthGuard from "./auth/AuthGuard";
import { Store } from "./redux/Store";

// import {getCategoryList} from './redux/actions/CategoryAction'
const App = () => {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <MatxTheme>
          <Auth>
            <Router history={history}>
              <Switch>
                <AuthGuard>
                  <MatxLayout />
                </AuthGuard>
              </Switch>
            </Router>
          </Auth>
        </MatxTheme>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
