const {GET_ORDER_LIST, ADD_ORDER_NEW_REQUEST, ADD_ORDER_SUCCESS, ORDER_FINISH_WORK} = require('./../actions/OrderAction');

const initialState = {
    getOrderList:[],
    loading:false,
    success:false
}

const OrderReducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_ORDER_LIST:
            return{
                ...state,
                getOrderList:action.payload
            }
            break;
        case ADD_ORDER_NEW_REQUEST:
            return{
                ...state,
                loading:true,
            }
            break;
        case ADD_ORDER_SUCCESS:
            return{
                ...state,
                loading:false,
                success:true
            }
            break;
        case ORDER_FINISH_WORK:
            return{
                ...state,
                success:false
            }
            break;
    
        default:
        return {
            ...state,
            success:false
        }
    }
}

export default OrderReducer