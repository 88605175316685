import { MatxLoadable } from "matx";

const StyleList = MatxLoadable({
  loader: () => import("./StyleList")
});


const styleRoutes = [
  {
    path: "/style/list",
    component: StyleList
  },

];

export default styleRoutes;