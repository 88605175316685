import { MatxLoadable } from "matx";

const CollectionList = MatxLoadable({
  loader: () => import("./CollectionList")
});

const CollectionMenu = MatxLoadable({
  loader: () => import("./CollectionMenu")
});

const collectionRoutes = [
  {
    path: "/collection/list",
    component: CollectionList
  },

  {
    path: "/collection/menu",
    component: CollectionMenu
  }
];

export default collectionRoutes;