import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const ADD_ORDER_NEW_REQUEST = "ADD_ORDER_NEW_REQUEST";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const EDIT_ORDER = "EDIT_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const FETCH_ORDER_REQUEST = "FETCH_ORDER_REQUEST";
export const ORDER_FINISH_WORK = "ORDER_FINISH_WORK";

export const getOrderList = () => dispatch => {
    axios.get(`${API_URLL}orders/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_ORDER_LIST,
            payload:res.data
        });
    });
}

export const addOrder = (payload) => dispatch => {
    dispatch({type:ADD_ORDER_NEW_REQUEST})
    axios.post(`${API_URLL}orders/create`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_ORDER_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:ORDER_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const updateOrder = (payload, id) => dispatch => {
 dispatch({type:ADD_ORDER_NEW_REQUEST})
    axios.put(`${API_URLL}orders/update/${id}`, 
    payload,{
        headers: {
            'x-access-token': `${token}`
        }
    })
    .then(res=>{
        dispatch({
            type:ADD_ORDER_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:ORDER_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const updateOrderStatus = (payload, id) => dispatch => {
    dispatch({type:ADD_ORDER_NEW_REQUEST})
       axios.put(`${API_URLL}orders/update-status/${id}`, 
       payload,{
           headers: {
               'x-access-token': `${token}`
           }
       })
       .then(res=>{
           dispatch({
               type:ADD_ORDER_SUCCESS,
               payload:res.data
           });
           setTimeout(() => {
               dispatch({type:ORDER_FINISH_WORK})
           }, 2000);
       })
       .catch(error=>{
           console.log('hello')
           console.log(error)
       })
   }

export const deleteOrder = ( id)   => {
    let conf = window.confirm("Are you sure to delete this order detail")
 if(conf){
      axios.delete(`${API_URLL}orders/delete/${id}`,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        
        alert(  res.data.message + ' please refresh the page to see updated order list' )
    })
    .catch(error=>{
        
       alert(error)
    })
 } else{return}
   
}

export const successOff = () => dispatch => {
    dispatch({
        type:ORDER_FINISH_WORK
    })
}