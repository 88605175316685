import {ADD_PRODUCT_SUCCESS, GET_PRODUCT_LIST, ADD_PRODUCT_NEW_REQUEST, PRODUCT_FINISH_WORK} from './../actions/ProductAction';

const initialState = {
    productList:[],
    loading:false,
    success:false
}

const ProductReducer = (state = initialState, action)=>{
    switch (action.type) {
        case GET_PRODUCT_LIST:
            state = {
                ...state,
                productList:action.payload
            }
            break;
    
        case ADD_PRODUCT_NEW_REQUEST:
            state = {
                ...state,
                loading:true,
            }
            break;

        case ADD_PRODUCT_SUCCESS:
            state = {
                ...state,
                loading:false,
                success:true
            }
            break;

        case PRODUCT_FINISH_WORK:
            state = {
                ...state,
                success:false
            }
            break;
    
        default:
        state =  {
            ...state,
            success:false
        }
        break

    }
    return state;
    
}

export default ProductReducer