import { MatxLoadable } from "matx";

const StonesList = MatxLoadable({
  loader: () => import("./StonesList")
});


const stonesRoutes = [
  {
    path: "/stones/list",
    component: StonesList
  },

];

export default stonesRoutes;