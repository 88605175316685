import { MatxLoadable } from "matx";

const SubscribeList = MatxLoadable({
  loader: () => import("./SubscribeList")
});


const SubscribeRoute = [
  {
    path: "/subscribe/list",
    component: SubscribeList
  },

];

export default SubscribeRoute;