import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'

export const SET_CUSTOMER_DAT = "CUSTOMER_SET_DAT";
export const REMOVE_CUSTOMER_DATA = "CUSTOMER_REMOVE_DATA";
export const CUSTOMER_LOGGED_OUT = "CUSTOMER_LOGGED_OUT";
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const ADD_CUSTOMER_NEW_REQUEST = "ADD_CUSTOMER_NEW_REQUEST";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const FETCH_CUSTOMER_REQUEST = "FETCH_CUSTOMER_REQUEST";
export const CUSTOMER_FINISH_WORK = "CUSTOMER_FINISH_WORK";

export const getCustomerList = ()  => dispatch => {
  axios.get(`${API_URLL}customer/list`,{
  headers: {
      'x-access-token': `${token}`
  }
})
  .then(res=>{
      dispatch({
          type:GET_CUSTOMER_LIST,
          payload:res.data
      });
  })
  .catch(error=>{
      console.log('error')
      console.log(error)
  })
}


export const addCustomer = (payload)  => dispatch =>{
  dispatch({type:ADD_CUSTOMER_NEW_REQUEST})
  axios.post(`${API_URLL}customer/create`,
  payload,{
  headers: {
      'x-access-token': `${token}`
  }
}
  )
  .then(res=>{
      dispatch({
          type:ADD_CUSTOMER,
          payload:res.data
      });
      setTimeout(() => {
          dispatch({type:CUSTOMER_FINISH_WORK})
      }, 2000);
  })
  .catch(error=>{
      console.log('hello')
      console.log(error)
  })
}

export const editCustomer = (payload) => dispatch => {
  dispatch({type:ADD_CUSTOMER_NEW_REQUEST})
  axios.post(`${API_URLL}customer/edit/${payload}`,{
  headers: {
      'x-access-token': `${token}`
  }
})
  .then(res=>{
      dispatch({type:EDIT_CUSTOMER, payload:res.data})
  }) 
}

export const updateCustomer = (payload, id) => dispatch => {
  
  dispatch({type:ADD_CUSTOMER_NEW_REQUEST})
   axios.put(`${API_URLL}customer/update/${id}`, 
   payload,{
    headers: {
      'x-access-token': `${token}`
    }
}
  )
  .then(res=>{
      dispatch({
          type:ADD_CUSTOMER,
          payload:res.data
      });
      setTimeout(() => {
          dispatch({type:CUSTOMER_FINISH_WORK})
      }, 2000);
  })
  .catch(error=>{
      console.log(`error ${error}`)
  })   
}

export const deleteCustomer = (payload, id) => dispatch => {
  dispatch({type:ADD_CUSTOMER_NEW_REQUEST})
  axios.delete(`${API_URLL}customer/delete/${id}`,{
          headers: {
      'x-access-token': `${token}`
  }
  })
  .then(res=>{
      dispatch({
          type:ADD_CUSTOMER,
          payload:res.data
      });
      setTimeout(() => {
          dispatch({type:CUSTOMER_FINISH_WORK})
      }, 2000);
  });
}

export const successOff = () => dispatch => {
  dispatch({
      type:CUSTOMER_FINISH_WORK
  })
}



