import {ADD_EMPLOYEE, EMPLOYEE_FINISH_WORK, EDIT_EMPLOYEE, ADD_EMPLOYEE_NEW_REQUEST, GET_EMPLOYEE_LIST} from './../actions/EmployeeAction';

const initialState = {
    employeeList:[],
    addEmployee:[],
    loading:false,
    success:false
}

// const successfunction_stop = () => {
//     setTimeout(() => {
//         return false
//     }, 1000);
// }

const EmployeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEE_LIST:
        return{
            ...state,
            employeeList:action.payload
        }
        break;
         case ADD_EMPLOYEE_NEW_REQUEST:
             return {
                 ...state,
                 loading:true,
                 success:false,
             }
        break;
        case ADD_EMPLOYEE:
        return{
            ...state,
            loading:false,
            success:true,
        }
        break;
        case EDIT_EMPLOYEE:
        return{
            ...state,
            loading:false,
        }
        break;
        case EMPLOYEE_FINISH_WORK:
        return{
            ...state,
            success:false
        }
        default:        
            return{
                ...state,
                success:false
            }
    }
}

export default EmployeeReducer