import { MatxLoadable } from "matx";

const SupportList = MatxLoadable({
  loader: () => import("./SupportList")
});


const supportRoutes = [
  {
    path: "/support/list",
    component: SupportList
  },

];

export default supportRoutes;