import axios from "axios";
import { API_URLL, token } from "./../../views/api/defaultapi";
export const GET_BANNER_LIST = "GET_BANNER_LIST";
export const ADD_BANNER_NEW_REQUEST = "ADD_BANNER_NEW_REQUEST";
export const ADD_BANNER_SUCCESS = "ADD_BANNER_SUCCESS";
export const EDIT_BANNER = "EDIT_BANNER";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";
export const FETCH_BANNER_REQUEST = "FETCH_BANNER_REQUEST";
export const BANNER_FINISH_WORK = "BANNER_FINISH_WORK";

export const getBannerList = () => (dispatch) => {
  axios
    .get(`${API_URLL}banner/list`, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_BANNER_LIST,
        payload: res.data,
      });
    });
};

export const addBanner = (payload) => (dispatch) => {
  dispatch({ type: ADD_BANNER_NEW_REQUEST });
  axios
    .post(`${API_URLL}banner/create`, payload, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_BANNER_SUCCESS,
        payload: res.data,
      });
      setTimeout(() => {
        dispatch({ type: BANNER_FINISH_WORK });
      }, 2000);
    })
    .catch((error) => {
      console.log("hello");
      console.log(error);
    });
};

export const updateBanner = (payload, id) => (dispatch) => {
  dispatch({ type: ADD_BANNER_NEW_REQUEST });
  axios
    .put(`${API_URLL}banner/update/${id}`, payload, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_BANNER_SUCCESS,
        payload: res.data,
      });
      setTimeout(() => {
        dispatch({ type: BANNER_FINISH_WORK });
      }, 2000);
    })
    .catch((error) => {
      console.log("hello");
      console.log(error);
    });
};

// export const deleteBanner = (payload, id) => (dispatch) => {
//   console.log("delete api calling");
//   dispatch({ type: DELETE_BANNER });
//   axios
//     .delete(`${API_URLL}banner/delete/${id}`, payload, {
//       headers: { "x-access-token": `${token}` },
//     })
//     .then((res) => {
//       // dispatch({type:DELETE_BANNER,payload:res.data})
//       console.log(res);
//       setTimeout(() => {
//         dispatch({ type: BANNER_FINISH_WORK });
//       }, 2000);
//     })
//     .catch((error) => {
//       console.log("error");
//       console.log(error);
//     });
// };

export const deleteBanner = (id) => {
  axios
    .delete(`${API_URLL}banner/delete/${id}`, {
      headers: { "x-access-token": `${token}` },
    })
    .then((data) => {
      alert(data.data.message)
    })
    .catch((error) => console.log(error));
};

export const successOff = () => (dispatch) => {
  dispatch({ type: BANNER_FINISH_WORK });
};
