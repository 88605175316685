import {
    GET_CUSTOMER_LIST,
    ADD_CUSTOMER_NEW_REQUEST,
    ADD_CUSTOMER,
    EDIT_CUSTOMER,
    UPDATE_CUSTOMER,
    DELETE_CUSTOMER,
    FETCH_CUSTOMER_REQUEST,
    CUSTOMER_FINISH_WORK
  } from "../actions/CustomerActions";
  
  const initialState = { customerList:[],  loading:false, success:false };
  
  const customerReducer = function(state = initialState, action) {
    switch (action.type) {
      
  
      case GET_CUSTOMER_LIST:
        return{
            ...state,
            customerList:action.payload
        }
        break;
         case ADD_CUSTOMER_NEW_REQUEST:
             return {
                 ...state,
                 loading:true,
                 success:false,
             }
        break;
        case ADD_CUSTOMER:
        return{
            ...state,
            loading:false,
            success:true,
        }
        break;
        case EDIT_CUSTOMER:
        return{
            ...state,
            loading:false,
        }
        break;
        case CUSTOMER_FINISH_WORK:
        return{
            ...state,
            success:false
        }
  
  
  
  
  
  
  
  
  
  
      default: {
        return state;
      }
    }
  };
  
  export default customerReducer;
  