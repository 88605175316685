import { MatxLoadable } from "matx";

const ProductList = MatxLoadable({
  loader: () => import("./ProductList")
});

const AddProduct = MatxLoadable({
  loader: () => import("./AddProduct")
});

const AddProductDiamond = MatxLoadable({
  loader: () => import("./AddProductDiamond")
});

const ProductDetails = MatxLoadable({
  loader: () => import("./ProductDetails")
});

const ProductUpdate = MatxLoadable({
  loader: () => import("./UpdateProduct")
});

const ProductUpdateDiamond = MatxLoadable({
  loader: () => import("./UpdateProductDiamond")
});

const Menu = MatxLoadable({
  loader: () => import("./Menu")
});

const productRoutes = [
  {
    path: "/product/list",
    component: ProductList
  },

  {
    path: "/product/add",
    component: AddProduct
  },

  {
    path: "/product/addDiamond",
    component: AddProductDiamond
  },

  {
    path: "/product/menu",
    component: Menu
  },

  {
    path: "/product/details/:productId",
    component: ProductDetails
  },

  {
    path: "/product/update/:productId",
    component: ProductUpdate
  },

  {
    path: "/product/updateDiamond/:productId",
    component: ProductUpdateDiamond
  }
];

export default productRoutes;