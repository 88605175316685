import { MatxLoadable } from "matx";

const GiftList = MatxLoadable({
  loader: () => import("./GiftList")
});


const giftRoutes = [
  {
    path: "/gift/list",
    component: GiftList
  },

];

export default giftRoutes;