import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_JEWELLERY_TYPE_LIST = "GET_JEWELLERY_TYPE_LIST";
export const ADD_JEWELLERY_TYPE_NEW_REQUEST = "ADD_JEWELLERY_TYPE_NEW_REQUEST";
export const ADD_JEWELLERY_TYPE = "ADD_JEWELLERY_TYPE";
export const EDIT_JEWELLERY_TYPE = "EDIT_JEWELLERY_TYPE";
export const UPDATE_JEWELLERY_TYPE = "UPDATE_JEWELLERY_TYPE";
export const DELETE_JEWELLERY_TYPE = "DELETE_JEWELLERY_TYPE";
export const FETCH_JEWELLERY_TYPE_REQUEST = "FETCH_JEWELLERY_TYPE_REQUEST";
export const JEWELLERY_TYPE_FINISH_WORK = "JEWELLERY_TYPE_FINISH_WORK";
export const ADD_JEWELLERY_TYPE_SUCCESS = "ADD_JEWELLERY_TYPE_SUCCESS";

export const getJewelleryTypeList = () => dispatch => {
    axios.get(`${API_URLL}jewellery-type/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_JEWELLERY_TYPE_LIST,
            payload:res.data
        });
    });
}

export const addJewelleryType = (payload) => dispatch => {
    
    // for (var [key, value] of payload.entries()) { 
    //     console.log("k, v -- " + key, value);
    // }
    // const formData = new FormData();
    // formData.append('Name',payload.Image)
    // formData.append('Image', payload.Image);

    // let obj = {};
    // for (let key of payload.keys()) {
    //     obj[key] = payload.get(key);
    //   }
    //   console.log(obj)
    dispatch({type:ADD_JEWELLERY_TYPE_NEW_REQUEST})
    axios.post(`${API_URLL}jewellery-type/create`, 
    payload,{
    headers: {
        'x-access-token': `${token}`,
        'Content-Type' : 'multipart/form-data',
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_JEWELLERY_TYPE_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:JEWELLERY_TYPE_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const updateJewelleryType = (payload, id) => dispatch => {
     dispatch({type:ADD_JEWELLERY_TYPE_NEW_REQUEST})
        axios.put(`${API_URLL}jewellery-type/update/${id}`, 
        payload,{
        headers: {
            'x-access-token': `${token}`
        }
    }
    )
    .then(res=>{
        dispatch({
            type:ADD_JEWELLERY_TYPE_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:JEWELLERY_TYPE_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const successOff = () => dispatch => {
    dispatch({type:JEWELLERY_TYPE_FINISH_WORK})
}

export const deleteJewelleryType = (payload, id) => dispatch => {
    dispatch({type:ADD_JEWELLERY_TYPE_NEW_REQUEST})
    axios.delete(`${API_URLL}jewellery-type/delete/${id}`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_JEWELLERY_TYPE,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:JEWELLERY_TYPE_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })   
}