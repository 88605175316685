const {GET_COLLECTION_LIST, ADD_COLLECTION_NEW_REQUEST, ADD_COLLECTION_SUCCESS, COLLECTION_FINISH_WORK} = require('./../actions/CollectionAction');

const initialState = {
    collectionList :[],
    loading:false,
    success:false
}

const CollectionReducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_COLLECTION_LIST:
            return{
                ...state,
                collectionList:action.payload
            }
            break;
        case ADD_COLLECTION_NEW_REQUEST:
            return{
                ...state,
                loading:true,
            }
            break;
        case ADD_COLLECTION_SUCCESS:
            return{
                ...state,
                loading:false,
                success:true
            }
            break;
        case COLLECTION_FINISH_WORK:
            return{
                ...state,
                success:false
            }
            break;
    
        default:
        return {
            ...state,
            success:false
        }
    }
}

export default CollectionReducer