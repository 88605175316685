const {GET_OFFER_LIST, ADD_OFFER_NEW_REQUEST, ADD_OFFER_SUCCESS, OFFER_FINISH_WORK} = require('./../actions/OfferAction');

const initialState = {
    getOfferList:[],
    loading:false,
    success:false
}

const OfferReducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_OFFER_LIST:
            return{
                ...state,
                getOfferList:action.payload
            }
            break;
        case ADD_OFFER_NEW_REQUEST:
            return{
                ...state,
                loading:true,
            }
            break;
        case ADD_OFFER_SUCCESS:
            return{
                ...state,
                loading:false,
                success:true
            }
            break;
        case OFFER_FINISH_WORK:
            return{
                ...state,
                success:false
            }
            break;
    
        default:
        return {
            ...state,
            success:false
        }
    }
}

export default OfferReducer