import axios from "axios";
import { API_URLL, token } from "./../../views/api/defaultapi";

export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const ADD_CATEGORY_NEW_REQUEST = "ADD_CATEGORY_NEW_REQUEST";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const FETCH_CATEGORY_REQUEST = "FETCH_CATEGORY_REQUEST";
export const CATEGORY_FINISH_WORK = "CATEGORY_FINISH_WORK";

export const getCategoryList = () => (dispatch) => {
  console.log(API_URLL);
  axios
    .get(`${API_URLL}category/list`, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_CATEGORY_LIST,
        payload: res.data,
      });
      console.log(res.data);
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
    });
};

export const addCategory = (payload) => (dispatch) => {
  dispatch({ type: ADD_CATEGORY_NEW_REQUEST });
  axios
    .post(`${API_URLL}category/create`, payload, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_CATEGORY,
        payload: res.data,
      });
      setTimeout(() => {
        dispatch({ type: CATEGORY_FINISH_WORK });
      }, 2000);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const editCategory = (payload) => (dispatch) => {
  dispatch({ type: ADD_CATEGORY_NEW_REQUEST });
  axios
    .post(`${API_URLL}category/edit/${payload}`, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({ type: EDIT_CATEGORY, payload: res.data });
    });
};

export const updateCategory = (payload, id) => (dispatch) => {
  dispatch({ type: ADD_CATEGORY_NEW_REQUEST });
  axios
    .put(`${API_URLL}category/update/${id}`, payload, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_CATEGORY,
        payload: res.data,
      });
      setTimeout(() => {
        dispatch({ type: CATEGORY_FINISH_WORK });
      }, 2000);
    })
    .catch((error) => {
      console.log(`error ${error}`);
    });
};

export const deleteCategory = (categoryId) => {
  axios
    .delete(`${API_URLL}category/delete/${categoryId}`, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      alert(res.data.message + "please refresh the page");
    })
    .catch((error) => {
      alert(error);
    });
};

export const successOff = () => (dispatch) => {
  dispatch({
    type: CATEGORY_FINISH_WORK,
  });
};
