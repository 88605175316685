import { MatxLoadable } from "matx";

const CouponList = MatxLoadable({
  loader: () => import("./CouponList")
});


const couponRoutes = [
  {
    path: "/coupon/list",
    component: CouponList
  },

];

export default couponRoutes;