import { MatxLoadable } from "matx";

const JewelleryTypeList = MatxLoadable({
  loader: () => import("./JewelleryTypeList")
});


const typeRoutes = [
  {
    path: "/type/list",
    component: JewelleryTypeList
  },

];

export default typeRoutes;