import { MatxLoadable } from "matx";

const OfferList = MatxLoadable({
  loader: () => import("./OfferList")
});


const offerRoutes = [
  {
    path: "/offer/list",
    component: OfferList
  },

];

export default offerRoutes;