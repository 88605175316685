const {GET_STONE_LIST, ADD_STONE_NEW_REQUEST, ADD_STONE_SUCCESS, STONE_FINISH_WORK} = require('./../actions/StoneAction');

const initialState = {
    getStonesList:[],
    loading:false,
    success:false
}

const StonesReducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_STONE_LIST:
            return{
                ...state,
                getStonesList:action.payload
            }
            break;
        case ADD_STONE_NEW_REQUEST:
            return{
                ...state,
                loading:true,
            }
            break;
        case ADD_STONE_SUCCESS:
            return{
                ...state,
                loading:false,
                success:true
            }
            break;
        case STONE_FINISH_WORK:
            return{
                ...state,
                success:false
            }
            break;
    
        default:
        return {
            ...state,
            success:false
        }
    }
}

export default StonesReducer