import { MatxLoadable } from "matx";

const CustomerList = MatxLoadable({
  loader: () => import("./CustomerList")
});
const CustomerMenu = MatxLoadable({
  loader: () => import("./CustomerMenu")
});

const customerRoutes = [
  {
    path: "/customers/list",
    component: CustomerList
  },

  {
    path: "/customers/menu",
    component: CustomerMenu
  }
];

export default customerRoutes;