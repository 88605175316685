import {ADD_METAL_NEW_REQUEST, ADD_METAL_SUCCESS, GET_METAL_LIST, METAL_FINISH_WORK} from  './../actions/MetalAction';

const initialState = {
    metalList:[],
    loading:false,
    success:false
}

const MetalReducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_METAL_LIST:
            return{
                ...state,
                metalList:action.payload
            }
            break;
        case ADD_METAL_NEW_REQUEST:
            return{
                ...state,
                loading:true,
            }
            break;
        case ADD_METAL_SUCCESS:
            return{
                ...state,
                loading:false,
                success:true
            }
            break;
        case METAL_FINISH_WORK:
            return{
                ...state,
                success:false
            }
            break;
    
        default:
        return {
            ...state,
            success:false
        }
    
    }
}

export default MetalReducer