import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'

export const GET_PRICE_LIST = "GET_PRICE_LIST";
export const ADD_PRICE_NEW_REQUEST = "ADD_PRICE_NEW_REQUEST";
export const ADD_PRICE = "ADD_PRICE";
export const EDIT_PRICE = "EDIT_PRICE";
export const UPDATE_PRICE = "UPDATE_PRICE";
export const DELETE_PRICE = "DELETE_PRICE";
export const FETCH_PRICE_REQUEST = "FETCH_PRICE_REQUEST";
export const PRICE_FINISH_WORK = "PRICE_FINISH_WORK";

export const getPriceList = ()  => dispatch => {
    axios.get(API_URLL+'price/list',{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_PRICE_LIST,
            payload:res.data
        });
    })
    .catch(error=>{
        console.log('error')
        console.log(error)
    })
}


export const addPrice = (payload)  => dispatch =>{
    dispatch({type:ADD_PRICE_NEW_REQUEST})
    axios.post(`${API_URLL}price/create`,
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_PRICE,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:PRICE_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const editPrice = (payload) => dispatch => {
    dispatch({type:ADD_PRICE_NEW_REQUEST})
    axios.post(`${API_URLL}price/edit/${payload}`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({type:EDIT_PRICE, payload:res.data})
    }) 
}

export const updatePrice = (payload, id) => dispatch => {
    dispatch({type:ADD_PRICE_NEW_REQUEST})
    axios.put(`${API_URLL}price/update/${id}`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_PRICE,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:PRICE_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log(`error ${error}`)
    })   
}

export const successOff = () => dispatch => {
    dispatch({
        type:PRICE_FINISH_WORK
    })
}