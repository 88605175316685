import { MatxLoadable } from "matx";

const ShippingList = MatxLoadable({
  loader: () => import("./ShippingList")
});


const shippingRoutes = [
  {
    path: "/shipping/list",
    component: ShippingList
  },

];

export default shippingRoutes;