import { MatxLoadable } from "matx";

const PurityList = MatxLoadable({
  loader: () => import("./PurityList")
});


const purityRoutes = [
  {
    path: "/purity/list",
    component: PurityList
  },

];

export default purityRoutes;