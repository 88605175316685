import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_STONE_LIST = "GET_STONE_LIST";
export const ADD_STONE_NEW_REQUEST = "ADD_STONE_NEW_REQUEST";
export const ADD_STONE_SUCCESS = "ADD_STONE_SUCCESS";
export const EDIT_STONE = "EDIT_STONE";
export const UPDATE_STONE = "UPDATE_STONE";
export const DELETE_STONE = "DELETE_STONE";
export const FETCH_STONE_REQUEST = "FETCH_STONE_REQUEST";
export const STONE_FINISH_WORK = "STONE_FINISH_WORK";

export const getStoneList = () => dispatch => {
     axios.get(`${API_URLL}stones/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_STONE_LIST,
            payload:res.data
        });
    });
}

export const addStone = (payload) => dispatch => {
     dispatch({type:ADD_STONE_NEW_REQUEST})
    axios.post(`${API_URLL}stones/create`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_STONE_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:STONE_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const updateStone = (payload, id) => dispatch => {
     dispatch({type:ADD_STONE_NEW_REQUEST})
    axios.put(`${API_URLL}stones/update/${id}`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
    }
    )
    .then(res=>{
        dispatch({
            type:ADD_STONE_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:STONE_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const successOff = () => dispatch => {
    dispatch({
        type:STONE_FINISH_WORK
    })
}

export const deleteStone = (payload, id) => dispatch => {
     dispatch({type:ADD_STONE_NEW_REQUEST})
    axios.delete(`${API_URLL}stones/update/${id}`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:ADD_STONE_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:STONE_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}