import axios from "axios";
import { API_URLL, token } from "./../../views/api/defaultapi";
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const ADD_PRODUCT_NEW_REQUEST = "ADD_PRODUCT_NEW_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const PRODUCT_FINISH_WORK = "PRODUCT_FINISH_WORK";

export const getProductList = () => (dispatch) => {
  axios
    .get(`${API_URLL}product/list`, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      console.log(res.data.data);
      dispatch({
        type: GET_PRODUCT_LIST,
        payload: res.data,
      });
    });
};

export const addProduct = (payload) => (dispatch) => {
  dispatch({ type: ADD_PRODUCT_NEW_REQUEST });
  axios
    .post(`${API_URLL}product/create`, payload, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_PRODUCT_SUCCESS,
        payload: res.data,
      });
      setTimeout(() => {
        dispatch({ type: PRODUCT_FINISH_WORK });
      }, 2000);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateProduct = (payload, id) => (dispatch) => {
  dispatch({ type: ADD_PRODUCT_NEW_REQUEST });
  axios
    .put(`${API_URLL}product/update/${id}`, payload, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_PRODUCT_SUCCESS,
        payload: res.data,
      });
      setTimeout(() => {
        dispatch({ type: PRODUCT_FINISH_WORK });
      }, 2000);
    });
};

export const deleteProduct = (payload, id) => (dispatch) => {
  dispatch({ type: ADD_PRODUCT_NEW_REQUEST });
  axios
    .delete(`${API_URLL}product/delete/${id}`, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_PRODUCT_SUCCESS,
        payload: res.data,
      });
      setTimeout(() => {
        dispatch({ type: PRODUCT_FINISH_WORK });
      }, 2000);
    });
};

export const successOff = () => (dispatch) => {
  dispatch({ type: ADD_PRODUCT_SUCCESS });
};
