import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_RETURNED_REPORT_LIST = "GET_RETURNED_REPORT_LIST";
export const REPORT_FINISH_WORK = "REPORT_FINISH_WORK";
export const GET_DELIVERD_REPORT_LIST = "GET_DELIVERD_REPORT_LIST"
export const UPDATE_REPORT = "UPDATE_REPORT"
export const REPORT_SUCCESS = "REPORT_SUCCESS"

export const getDeliverdReportList = () => dispatch => {
    axios.get(`${API_URLL}reports/deliverd/list`,{
        headers: {
            'x-access-token': `${token}`
        }
    })
    .then(res=>{
        dispatch({
            type:GET_DELIVERD_REPORT_LIST,
            payload:res.data
        });
    });
}

export const getReturnedReportList = () => dispatch => {
    axios.get(`${API_URLL}reports/returned/list`,{
        headers: {
            'x-access-token': `${token}`
        }
    })
    .then(res=>{
        dispatch({
            type:GET_RETURNED_REPORT_LIST,
            payload:res.data
        });
    });
}

export const updateOrderStatus = (payload, id) => dispatch => {
    dispatch({type:UPDATE_REPORT})
       axios.put(`${API_URLL}reports/update-status/${id}`, 
       payload,{
           headers: {
               'x-access-token': `${token}`
           }
       })
       .then(res=>{
           dispatch({
               type:REPORT_SUCCESS,
               payload:res.data
           });
           setTimeout(() => {
               dispatch({type:REPORT_FINISH_WORK})
           }, 2000);
       })
       .catch(error=>{
           console.log('hello')
           console.log(error)
       })
 }

export const successOff = () => dispatch => {
    dispatch({
        type:REPORT_FINISH_WORK
    })
}