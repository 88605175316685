const {GET_PURITY_LIST, ADD_PURITY_NEW_REQUEST, ADD_PURITY_SUCCESS, PURITY_FINISH_WORK} = require('./../actions/PurityAction');

const initialState = {
    getPurityList:[],
    loading:false,
    success:false
} 

const PurityReducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_PURITY_LIST:
            return{
                ...state,
                getPurityList:action.payload
            }
            break;
        case ADD_PURITY_NEW_REQUEST:
            return{
                ...state,
                loading:true,
            }
            break;
        case ADD_PURITY_SUCCESS:
            return{
                ...state,
                loading:false,
                success:true
            }
            break;
        case PURITY_FINISH_WORK:
            return{
                ...state,
                success:false
            }
            break;
    
        default:
        return {
            ...state,
            success:false
        }
    }
}

export default PurityReducer