import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_PURITY_LIST = "GET_PURITY_LIST";
export const ADD_PURITY_NEW_REQUEST = "ADD_PURITY_NEW_REQUEST";
export const ADD_PURITY_SUCCESS = "ADD_PURITY_SUCCESS";
export const EDIT_PURITY = "EDIT_PURITY";
export const UPDATE_PURITY = "UPDATE_PURITY";
export const DELETE_PURITY = "DELETE_PURITY";
export const FETCH_PURITY_REQUEST = "FETCH_PURITY_REQUEST";
export const PURITY_FINISH_WORK = "PURITY_FINISH_WORK";

export const getPurityList = () => dispatch => {
    axios.get(`${API_URLL}purity/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_PURITY_LIST,
            payload:res.data
        });
    });
}

export const addPurity = (payload) => dispatch => {
    dispatch({type:ADD_PURITY_NEW_REQUEST})
    axios.post(`${API_URLL}purity/create`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_PURITY_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:PURITY_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const updatePurity = (payload, id) => dispatch => {
    dispatch({type:ADD_PURITY_NEW_REQUEST})
    axios.put(API_URLL+"purity/update/"+id, 
    payload,
    {
        headers: {
            'x-access-token': `${token}`
        }
    })
    .then(res=>{
        dispatch({
            type:ADD_PURITY_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:PURITY_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log(error)
    })
}

export const deletePurity = (payload, id) => dispatch => {
 dispatch({type:ADD_PURITY_NEW_REQUEST})
    axios.delete(`${API_URLL}purity/delete/${id}`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:ADD_PURITY_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:PURITY_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const successOff = () => dispatch => {
    dispatch({
        type:PURITY_FINISH_WORK
    })
}