import axios from "axios";
import jwtAuthService from "../../services/jwtAuthService";
import { API_URLL, token } from "./../../views/api/defaultapi";

export const SET_USER_DAT = "USER_SET_DAT";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const GET_USER_LIST = "GET_USER_LIST";
export const ADD_USER_NEW_REQUEST = "ADD_USER_NEW_REQUEST";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const USER_FINISH_WORK = "USER_FINISH_WORK";

export const getUserList = () => (dispatch) => {
  axios
    .get(`${API_URLL}user/list`, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_USER_LIST,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log("error");
      console.log(error);
    });
};

export const addUser = (payload) => (dispatch) => {
  dispatch({ type: ADD_USER_NEW_REQUEST });
  axios
    .post(`${API_URLL}user/create`, payload, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_USER,
        payload: res.data,
      });
      setTimeout(() => {
        dispatch({ type: USER_FINISH_WORK });
      }, 2000);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const editUser = (payload) => (dispatch) => {
  dispatch({ type: ADD_USER_NEW_REQUEST });
  axios
    .post(`${API_URLL}user/edit/${payload}`, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({ type: EDIT_USER, payload: res.data });
    });
};

export const updateUser = (payload, id) => (dispatch) => {
  dispatch({ type: ADD_USER_NEW_REQUEST });
  axios
    .put(`${API_URLL}user/update/${id}`, payload, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_USER,
        payload: res.data,
      });
      setTimeout(() => {
        dispatch({ type: USER_FINISH_WORK });
      }, 2000);
    })
    .catch((error) => {
      console.log(`error ${error}`);
    });
};

export const deleteUser = (id) => {
  axios
    .delete(`${API_URLL}user/delete/${id}`, {
      headers: {
        "x-access-token": `${token}`,
      },
    })
    .then((res) => {
      alert(res.data.message);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const successOff = () => (dispatch) => {
  dispatch({
    type: USER_FINISH_WORK,
  });
};

export function setUserData(user) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DAT,
      data: user,
    });
  };
}

export function logoutUser() {
  return (dispatch) => {
    jwtAuthService.logout();
    // history.push({
    //   pathname: "/session/signin"
    // });
    window.location.href = "/session/signin";

    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}
