const {GET_DELIVERD_REPORT_LIST, GET_RETURNED_REPORT_LIST, REPORT_SUCCESS, UPDATE_REPORT, REPORT_FINISH_WORK} = require('./../actions/ReportAction');

const initialState = {
    getDeliverdReportList:[],
    getReturnedReportList:[],
    loading:false,
    success:false
} 

const ReportReducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_DELIVERD_REPORT_LIST:
            return{
                ...state,
                getDeliverdReportList:action.payload 
            }
            break;

            case GET_RETURNED_REPORT_LIST:
            return{
                ...state,
                getReturnedReportList:action.payload
            }
            break;

            case UPDATE_REPORT:
                return{
                    ...state,
                    loading:true,
                }
                break;

            case REPORT_SUCCESS:
                return{
                    ...state,
                    loading:false,
                    success:true
                }
                break;
        
        case REPORT_FINISH_WORK:
            return{
                ...state,
                success:false
            }
            break;
    
        default:
        return {
            ...state,
            success:false
        }
    }
}

export default ReportReducer