import { MatxLoadable } from "matx";

const OrderList = MatxLoadable({
  loader: () => import("./OrderList")
});

const AddOrder = MatxLoadable({
  loader: () => import("./AddOrder")
});

const OrderMenu = MatxLoadable({
  loader: () => import("./OrderMenu")
});

const OrderInvoice = MatxLoadable({
  loader: () => import("./Invoice")
});

const OrderDetails = MatxLoadable({
  loader: () => import("./OrderDetails")
});

const orderRoutes = [
  {
    path: "/orders/list",
    component: OrderList
  },

  {
    path: "/orders/add",
    component: AddOrder
  },

  {
    path: "/orders/menu",
    component: OrderMenu
  },

  {
    path: "/orders/invoice",
    component: OrderInvoice
  },

  {
    path: "/orders/details/:orderId",
    component: OrderDetails
  }
];

export default orderRoutes;