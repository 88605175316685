import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_TRYATHOME_LIST = "GET_TRYATHOME_LIST";
export const TRYATHOME_FINISH_WORK = "TRYATHOME_FINISH_WORK";

export const getTryathomeList = () => dispatch => {
     axios.get(`${API_URLL}tryathomes/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_TRYATHOME_LIST,
            payload:res.data
        });
    });
}


export const successOff = () => dispatch => {
    dispatch({
        type:TRYATHOME_FINISH_WORK
    })
}
