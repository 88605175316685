import { ADD_PRICE, ADD_PRICE_NEW_REQUEST, EDIT_PRICE, GET_PRICE_LIST, PRICE_FINISH_WORK } from './../actions/PriceAction';

const initialState = {
    priceList: [],
    addPrice: [],
    loading: false,
    success: false
};

const PriceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRICE_LIST:
            return {
                ...state,
                priceList: action.payload
            };
        case ADD_PRICE_NEW_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            };
        case ADD_PRICE:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case EDIT_PRICE:
            return {
                ...state,
                loading: false,
            };
        case PRICE_FINISH_WORK:
            return {
                ...state,
                success: false
            };
        default:
            return state;
    }
};

export default PriceReducer;