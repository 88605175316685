import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_TESTIMONIAL_LIST = "GET_TESTIMONIAL_LIST";
export const ADD_TESTIMONIAL_NEW_REQUEST = "ADD_TESTIMONIAL_NEW_REQUEST";
export const ADD_TESTIMONIAL_SUCCESS = "ADD_TESTIMONIAL_SUCCESS";
export const EDIT_TESTIMONIAL = "EDIT_TESTIMONIAL";
export const UPDATE_TESTIMONIAL = "UPDATE_TESTIMONIAL";
export const DELETE_TESTIMONIAL = "DELETE_TESTIMONIAL";
export const FETCH_TESTIMONIAL_REQUEST = "FETCH_TESTIMONIAL_REQUEST";
export const TESTIMONIAL_FINISH_WORK = "TESTIMONIAL_FINISH_WORK";

export const getTestimonialList = () => dispatch => {
     axios.get(`${API_URLL}testimonial/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_TESTIMONIAL_LIST,
            payload:res.data
        });
    });
}

export const addTestimonial = (payload) => dispatch => {
     dispatch({type:ADD_TESTIMONIAL_NEW_REQUEST})
    axios.post(`${API_URLL}testimonial/create`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_TESTIMONIAL_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:TESTIMONIAL_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const updateTestimonial = (payload, id) => dispatch => {
     dispatch({type:ADD_TESTIMONIAL_NEW_REQUEST})
    axios.put(`${API_URLL}testimonial/update/${id}`, 
    payload,{
    headers: {
        'x-access-token': `${token}`
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_TESTIMONIAL_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:TESTIMONIAL_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const successOff = () => dispatch => {
    dispatch({
        type:TESTIMONIAL_FINISH_WORK
    })
}

export const deleteTestimonial = (payload, id) => dispatch => {
     dispatch({type:ADD_TESTIMONIAL_NEW_REQUEST})
    axios.delete(`${API_URLL}testimonial/update/${id}`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:ADD_TESTIMONIAL_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:TESTIMONIAL_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}