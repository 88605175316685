import React from "react";
import { Redirect } from "react-router-dom";
import categoryRoutes from "./views/Category/CategoryRoutes";
import bannerRoutes from "./views/Banner/BannerRoutes";
import priceRoutes from "./views/Price/PriceRoutes";
import TryAtHomeRoute from "./views/TryAtHome/TryAtHomeRoute";
import styleRoutes from "./views/Style/StyleRoutes";
import collectionRoutes from "./views/Collection/CollectionRoutes";
import occationRoutes from "./views/Occation/OccationRoutes";
import employeeRoutes from "./views/Employee/EmployeeRoutes";
import productRoutes from "./views/Product/ProductRoutes";
import stonesRoutes from "./views/Stones/StonesRoutes";
import metalRoutes from "./views/Metal/MetalRoutes";
import giftRoutes from "./views/Gift/GiftRoutes";
import SubscribeRoute from "./views/Subscribe/SubscribeRoute";
import typeRoutes from "./views/JewelleryType/JewelleryTypeRoutes";
import tagsRoutes from "./views/Tags/TagsRoutes";
import purityRoutes from "./views/Purity/PurityRoutes";
import offerRoutes from "./views/Offer/OfferRoutes";
import couponRoutes from "./views/Coupon/CouponRoutes";
import orderRoutes from "./views/Orders/OrderRoutes";
import customerRoutes from "./views/Customer/CustomerRoutes";
import dashboardRoutes from "./views/Dashboard/DashboardRoutes";
import shippingRoutes from "./views/Shipping/ShippingRoutes";
import reportRoutes from "./views/Report/ReportRoutes";
import supportRoutes from "./views/Support/SupportRoutes";
// import giftRoutes from "./views/Gifts/GiftRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";

// const redirectRoute = [
//   {
//     path: "/",
//     exact: true,
//     component: () => <Redirect to="/" />
//   }
// ];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

 
const routes = [
  
  ...categoryRoutes,
  ...priceRoutes ,
  ...TryAtHomeRoute,
  ...bannerRoutes,
  ...styleRoutes,
  ...collectionRoutes,
  ...occationRoutes,
  ...productRoutes,
  ...employeeRoutes,
  ...typeRoutes,
  ...metalRoutes,
  ...giftRoutes,
  ...SubscribeRoute,
  ...purityRoutes,
  ...offerRoutes,
  ...couponRoutes,
  ...tagsRoutes,
  ...stonesRoutes,
  ...orderRoutes,
  ...customerRoutes,
  ...reportRoutes,

  ...dashboardRoutes,
  
  ...shippingRoutes,
  
  ...supportRoutes,


  ...sessionRoutes,
];

export default routes;
