import { MatxLoadable } from "matx";

const EmployeeList = MatxLoadable({
  loader: () => import("./EmployeeList")
});

const AddEmployee = MatxLoadable({
  loader: () => import("./AddEmployee")
});

const EmployeeMenu = MatxLoadable({
  loader: () => import("./EmployeeMenu")
});

const UpdateEmployee = MatxLoadable({
  loader: () => import("./UpdateEmployee")
});

const UserList = MatxLoadable({
  loader: () => import("./UserList")
});

const UserDetails = MatxLoadable({
  loader: () => import("./UserDetails")
});

const UpdateUser = MatxLoadable({
  loader: ()=> import("./UpdateUser")
})

const CustomerList = MatxLoadable({
  loader: () => import("./CustomerList")
});


const CustomerDetails = MatxLoadable({
  loader: () => import("./CustomerDetails")
});



const employeeRoutes = [
  {
    path: "/employee/list",
    component: EmployeeList
  },

  {
    path: "/user/list",
    component: UserList
  },

  {
    path: "/customer/list",
    component: CustomerList
  },

  {
    path: "/employee/add",
    component: AddEmployee
  },

  {
    path: "/user/details/:userId",
    component: UserDetails
  },
  {
    path:"/update-user/:userId",
    component: UpdateUser
  },


  {
    path: "/customer/details/:userId",
    component: CustomerDetails
  },

  

  {
    path: "/employee/update/:employeeId",
    component: UpdateEmployee
  },

  
  {
    path: "/users/menu",
    component: EmployeeMenu
  }
];

export default employeeRoutes;