import axios from 'axios';
import {API_URLL, token} from './../../views/api/defaultapi'
export const GET_COLLECTION_LIST = "GET_COLLECTION_LIST"
export const ADD_COLLECTION_NEW_REQUEST = "ADD_COLLECTION_NEW_REQUEST"
export const ADD_COLLECTION_SUCCESS = "ADD_COLLECTION_SUCCESS"
export const EDIT_COLLECTION = "EDIT_COLLECTION"
export const UPDATE_COLLECTION = "UPDATE_COLLECTION"
export const DELETE_COLLECTION = "DELETE_COLLECTION"
export const COLLECTION_FINISH_WORK = "COLLECTION_FINISH_WORK";

export const getCollectionList = () => dispatch => {
     axios.get(`${API_URLL}collection/list`,{
    headers: {
        'x-access-token': `${token}`
    }
  })
    .then(res=>{
        dispatch({
            type:GET_COLLECTION_LIST,
            payload:res.data
        });
    });
}

export const addCollection = (payload) => dispatch => {
     dispatch({type:ADD_COLLECTION_NEW_REQUEST})
    axios.post(`${API_URLL}collection/create`, 
    payload,{
    headers: {
        'x-access-token': `${token}`,
        'Content-Type' : 'multipart/form-data',
    }
  }
    )
    .then(res=>{
        dispatch({
            type:ADD_COLLECTION_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:COLLECTION_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const updateCollection = (payload, id) => dispatch => {
    dispatch({type:ADD_COLLECTION_NEW_REQUEST})
    console.log("payload " +payload)
    axios.put(`${API_URLL}collection/update/${id}`, 
    payload,{
        headers: {
            'x-access-token': `${token}`
        }
    }
    )
    .then(res=>{
        dispatch({
            type:ADD_COLLECTION_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:COLLECTION_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}

export const successOff = () => dispatch => {
    dispatch({
        type:COLLECTION_FINISH_WORK
    })
}

export const deleteCollection = (payload, id) => dispatch => {
    dispatch({type:ADD_COLLECTION_NEW_REQUEST})
    axios.delete(`${API_URLL}collection/update/${id}`)
    .then(res=>{
        dispatch({
            type:ADD_COLLECTION_SUCCESS,
            payload:res.data
        });
        setTimeout(() => {
            dispatch({type:COLLECTION_FINISH_WORK})
        }, 2000);
    })
    .catch(error=>{
        console.log('hello')
        console.log(error)
    })
}