import {
  SET_USER_DAT,
  REMOVE_USER_DATA,
  USER_LOGGED_OUT,

  GET_USER_LIST,
  ADD_USER_NEW_REQUEST,
  ADD_USER,
  EDIT_USER,
  UPDATE_USER,
  DELETE_USER,
  FETCH_USER_REQUEST,
  USER_FINISH_WORK
} from "../actions/UserActions";

const initialState = { userList:[],  loading:false, success:false };

const userReducer = function(state = initialState, action) {
  switch (action.type) {
    case SET_USER_DAT: {
      return {
        ...state,
        ...action.data
      };
    }
    case REMOVE_USER_DATA: {
      return {
        ...state
      };
    }
    case USER_LOGGED_OUT: {
      return state;
    }

    case GET_USER_LIST:
      return{
          ...state,
          userList:action.payload
      }
      break;
       case ADD_USER_NEW_REQUEST:
           return {
               ...state,
               loading:true,
               success:false,
           }
      break;
      case ADD_USER:
      return{
          ...state,
          loading:false,
          success:true,
      }
      break;
      case EDIT_USER:
      return{
          ...state,
          loading:false,
      }
      break;
      case USER_FINISH_WORK:
      return{
          ...state,
          success:false
      }










    default: {
      return state;
    }
  }
};

export default userReducer;
