import { MatxLoadable } from "matx";

// ----------------------------

const BannerListComponent = MatxLoadable({
  loader: () => import("./BannerList")
});


const BannerRoutes = [
  {
    path: "/banner/list",
    component: BannerListComponent
  },

];

export default BannerRoutes;