import { MatxLoadable } from "matx";

const OccasionList = MatxLoadable({
  loader: () => import("./OccasionList")
});


const OccasionMenu = MatxLoadable({
  loader: () => import("./OccasionMenu")
});

const occasionRoutes = [
  {
    path: "/occasion/list",
    component: OccasionList
  },



  {
    path: "/occasion/menu",
    component: OccasionMenu
  }
];

export default occasionRoutes;