import { MatxLoadable } from "matx";
import SignIn from './../sessions/SignIn';

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: true,
      // mode: "close"
    }
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false
    },
    navbar: { show: false }
  },
  secondarySidebar: { show: false },
  footer: { show: false }
};

const PriceList = MatxLoadable({
  loader: () => import("./PriceList")
});

const PriceMenu = MatxLoadable({
  loader: () => import("./PriceMenu")
});

const priceRoutes = [
  {
    path: "/price/list",
    component: PriceList
  },

  {
    path: "/price/menu",
    component: PriceMenu
  },

   {
    path: "/session/signin",
    component: SignIn,
    settings
  },

];

export default priceRoutes;