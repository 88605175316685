import axios from "axios";
import { API_URLL } from "../views/api/defaultapi";
import localStorageService from "./localStorageService";
class JwtAuthService {
  // Dummy user object just for the demo
  user = {};

  // You need to send http request with email and passsword to your server in this method
  // Your server will return user object & a Token
  // User should have role property
  // You can define roles in app/auth/authRoles.js
  loginWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(API_URLL + "signin", {
          Email: email,
          Password: password,
        })
        .then((userdata) => {
          console.log(userdata.data);
          this.user.token = userdata.data.token;
          this.user.userId = userdata.data.user._id;
          this.user.role = userdata.data.user.Role;
          this.user.email = userdata.data.user.Email;
          this.user.age = 25;
        })
        .catch((err) => {
          console.log(err);
        });

      setTimeout(() => {
        resolve(this.user);
      }, 1000);
    }).then((data) => {
      // Login successful
      // Save token
      this.setSession(data.token);
      // Set user
      this.setUser(data);
      return data;
    });
  };

  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded
  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then((data) => {
      // Token is valid
      this.setSession(data.token);
      this.setUser(data);
      return data;
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  };

  // Set token to all http request header, so you don't need to attach everytime
  setSession = (token) => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Save user to localstorage
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("auth_user");
  };
}

export default new JwtAuthService();
